import React from "react";
import { NavLink} from 'react-router-dom';

function Heder() {
  var x = window.matchMedia("(max-width: 990px)");
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }
  const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }
 return(
  <>
    <header id="header" className="fixed-top d-flex align-items-center">
    <div className="container d-flex justify-content-between">

      <div className="logo">
        {/* <h1><NavLink to="/">Maxim</NavLink></h1> */}
        {/* <!-- Uncomment below if you prefer to use an image logo --> */}
        <NavLink to="/"><img src="assets/img/IMG_1674636363016.png" alt="" style={{maxHeight : "58px"}} className="img-fluid"/></NavLink>
      </div>

      <nav id="navbar" className="navbar">
        <ul>
          <li><NavLink className="nav-link scrollto " to="/" onClick={(e)=>{
            topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Home</NavLink></li>
          <li><NavLink className="nav-link scrollto" to="/About"onClick={(e)=>{
            topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>About</NavLink></li>

          <li><NavLink className="nav-link scrollto" to="/Team" onClick={(e)=>{
            topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }} >Team</NavLink></li>

          <li className="dropdown" ><NavLink to="/Fully-Automatic" onClick={(e)=>{
            if (select('#navbar').classList.contains('navbar-mobile')) {
              e.preventDefault()
              console.log("yes");
              e.currentTarget.nextElementSibling.classList.toggle('dropdown-active')
            }
          }}><span>Machine</span> <i className="bi bi-chevron-down"></i></NavLink>
            <ul>
              <li><NavLink to="/Fully-Automatic" onClick={(e)=>{
            topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Fully Automatic</NavLink></li>
              
              <li><NavLink to="/Roller-Pan-Mixer" onClick={(e)=>{
            topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Roller Pan Mixer</NavLink></li>
              <li><NavLink to="/Colour-Mixer" onClick={(e)=>{
            topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Colour Mixer</NavLink></li>
              <li><NavLink to="/Vibrator-table-set" onClick={(e)=>{
            topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Vibrator table set</NavLink></li>
              <li><NavLink to="/Vibrator" onClick={(e)=>{
            topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Vibrator</NavLink></li>
            </ul>
          </li>
          <li><NavLink className="nav-link scrollto" to="/Contact"onClick={(e)=>{
            topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Contact</NavLink></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"
        onClick={(e)=>{
          select('#navbar').classList.toggle('navbar-mobile')
          this.classList.toggle('bi-list')
          this.classList.toggle('bi-x')
          if (select(this.hash)) {
              e.preventDefault()
        
              let navbar = select('#navbar')
              if (navbar.classList.contains('navbar-mobile')) {
                navbar.classList.remove('navbar-mobile')
                let navbarToggle = select('.mobile-nav-toggle')
                navbarToggle.classList.toggle('bi-list')
                navbarToggle.classList.toggle('bi-x')
              }
              scrollto(this.hash)
            }
        }}></i>
      </nav>
      {/* <!-- .navbar --> */}

    </div>
  </header>
  </>
 );
}
export default Heder;