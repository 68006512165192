import React from "react";
import { NavLink } from "react-router-dom";
function Product4() {
    return(
        <>
            {/* <!-- ======= Breadcrumbs Section ======= --> */}
    <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Vibrator table set Details</h2>
          <ol>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink  >Machine</NavLink></li>
            <li>Vibrator table set Details</li>
          </ol>
        </div>

      </div>
    </section>
    {/* <!-- Breadcrumbs Section --> */}

    {/* <!-- ======= Portfolio Details Section ======= --> */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">

        <div className="row gy-4">
          <h3 className="center portfolio-info p"><b>Vibrator table set</b></h3>
        <div className="col-lg-3"></div>
          <div className="col-lg-6"  data-aos="fade-up">
            <div className="portfolio-details-slider portfolio-info swiper">
              <div className="swiper-wrapper align-items-center">

                <div className="swiper-slide">
                  <img src="assets/img/nesta-paver-block-machinery-2008300805-x4xudebi.jpg" alt="Vibrator table set nesta-paver-block-machinery-2008300805-x4xudebi"/>
                </div>

               
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        <div className="col-lg-3"></div>

         

        </div>

      </div>
    </section>
    {/* <!-- End Portfolio Details Section --> */}
        </>
    );
}
export default Product4;