import React from "react";
import { NavLink } from "react-router-dom";
function Team() {
    return(
        <>
    {/* <!-- ======= Breadcrumbs Section ======= --> */}
    <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Team </h2>
          <ol>
            <li><NavLink to="/">Home</NavLink></li>
            <li>Team</li>
          </ol>
        </div>

      </div>
    </section>
    {/* <!-- Breadcrumbs Section --> */}
    {/* <!-- ======= Team Section ======= --> */}
    <section id="team" className="team">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Team</h2>
        </div>

        <div className="row">

          <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up">
            <div className="member">
              
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div className="member">
              <img src="assets/img/team/IMG_20230106_112746.jpg" className="img-fluid" alt=""/>
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Parmar Ratilal</h4>
                  <span>Owner</span>
                </div>
                <div className="social">
                  <a href="https://wa.me/message/AGV6JU77PRQVP1"><i className="bi bi-whatsapp"></i></a>
                  <a href="https://www.facebook.com/parmar.ratilal.9699?mibextid=ZbWKwL"><i className="bi bi-facebook"></i></a>
                  <a href="https://instagram.com/rd._.parmar._.1210?igshid=ZDdkNTZiNTM="><i className="bi bi-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div className="member">
              <img src="assets/img/IMG_20230211_130716.jpg" className="img-fluid" alt=""/>
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Dharamshing bhai</h4>
                  <span>Owner</span>
                </div>
                <div className="social">
                <a href="https://wa.me/message/AGV6JU77PRQVP1"><i className="bi bi-whatsapp"></i></a>
                  <a href="https://www.facebook.com/parmar.ratilal.9699?mibextid=ZbWKwL"><i className="bi bi-facebook"></i></a>
                  <a href="https://instagram.com/rd._.parmar._.1210?igshid=ZDdkNTZiNTM="><i className="bi bi-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div className="member">
            </div>
          </div>

        </div>

      </div>
    </section>
    {/* <!-- End Team Section --> */}
        </>
    );
}
export default Team;