import React from 'react';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
export default function Slieder() {
  return (
    <MDBCarousel  className=""   dark fade>
      
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src='assets\img\photo_2023-02-15_22-53-8.jpg'
        alt='...'
      >
        <div className="carousel-content ">
          {/* <h2 className="animate__animated animate__fadeInDown"> jkhu</h2>
          <h1 className="animate__animated animate__fadeInDown" style={{color : "yellow"}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Welcome to Om Hydrauic</h1> */}
            <p className="animate__animated animate__fadeInUp"></p>
        </div>
      </MDBCarouselItem>
    </MDBCarousel>
  );
}