import React from "react";
import { NavLink} from 'react-router-dom';
function Product1() {
    return(
        <>
            {/* <!-- ======= Breadcrumbs Section ======= --> */}
    <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center" >
          <h2>Fully Automatic Fly Ash Bricks Machine Details</h2>
          <ol>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink>Machine</NavLink></li>
            <li>Fully Automatic Fly Ash Bricks Machine</li>
          </ol>
        </div>

      </div>
    </section>
    {/* <!-- Breadcrumbs Section --> */}

    {/* <!-- ======= Portfolio Details Section ======= --> */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">

        <div className="row gy-4">

          <div className="col-lg-7"  data-aos="fade-up">
            <div className="portfolio-details-slider swiper">
              <div className="swiper-wrapper align-items-center">

                <div className="swiper-slide">
                  <img src="assets/img/photo_2023-02-11_11-46-05.jpg" alt="Fully Automatic Fly Ash Bricks Machine"/>
                </div>

               
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>

          <div className="col-lg-4"  data-aos="fade-up">
            <div className="portfolio-info">
              <h3>Fully Automatic Fly Ash Bricks Machine</h3>

              <ul className="row">
                <li><strong>Maximum High Pressure Tonage</strong>   : 70</li>
                <li><strong>Total Power Required</strong>           : 21 H.P.</li>
                <li><strong>Manufacturing Capacity</strong>         : 10 pcs/stroke</li>
                <li><strong >Bricks Size</strong>                    : 230x100x75mm</li>
                <li><strong>Block Size</strong>                     : 400x200x200mm</li>
                <li><strong>Oil Tank Capacity</strong>              : 400 Liters</li>
              </ul>
            </div>
            <div className="portfolio-description">
              <p>
              </p>
            </div>
          </div>

        </div>

      </div>
    </section>
    {/* <!-- End Portfolio Details Section --> */}
        </>
    );
}
export default Product1;