import React from "react";
import { NavLink } from "react-router-dom";
function Product2() {
    return(
        <>
            {/* <!-- ======= Breadcrumbs Section ======= --> */}
    <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Roller Pan Mixer Details</h2>
          <ol>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink >Machine</NavLink></li>
            <li>Roller Pan Mixer Details</li>
          </ol>
        </div>

      </div>
    </section>
    {/* <!-- Breadcrumbs Section --> */}

    {/* <!-- ======= Portfolio Details Section ======= --> */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">

        <div className="row gy-4">

          <div className="col-lg-4"  data-aos="fade-up">
            <div className="portfolio-details-slider swiper">
              <div className="swiper-wrapper align-items-center">

                <div className="swiper-slide">
                  <img src="assets/img/blade-pan-mixer-machine-1618817359-5794408.jpeg" alt="Roller Pan Mixer Roller Pan Mixer"/>
                </div>

               
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>

          <div className="col-lg-8"  data-aos="fade-up">
            <div className="portfolio-info">
              <h3>Roller Pan Mixer</h3>

              <ul className="row">
                <li><strong>Capacity</strong>   : 250 Kg to 450 Kg / Batch</li>
                <li><strong>Power (Motor)</strong>           :  5 HP to 10 HP</li>
              </ul>
            </div>
            <div className="portfolio-description">
              <h2>Roller Pan Mixer Description</h2>
              <p>
              ● Manufacturing Mixing of raw materials
              of Fly-Ash bricks and second
              layer of paver blocks <br/>
              ● Material Charge Manual or Conveyor
              Discharge (Manual) Bottom Door opening
              Mixing By <br/>
              ● heavy Rollers and hand
              faced scrapers
              </p>
            </div>
          </div>

        </div>

      </div>
    </section>
    {/* <!-- End Portfolio Details Section --> */}
        </>
    );
}
export default Product2;