import React from "react";
import {  useState } from "react";
import { NavLink } from "react-router-dom";
function Contact() {
  const [text, setText] = useState("");
  const HandleOnclick =()=>{
  
   window.open(`https://wa.me/+916353635583?text=${text}`, '_blank');
   setText("");
   }
   const Handleonchange = (event) => {

       setText(event.target.value);
     }
      
    return(
        <>
            {/* <!-- ======= Breadcrumbs Section ======= --> */}
    <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Contact </h2>
          <ol>
            <li><NavLink to="/">Home</NavLink></li>
            <li>Contact</li>
          </ol>
        </div>

      </div>
    </section>
    {/* <!-- Breadcrumbs Section --> */}
    {/* <!-- ======= Contact Section ======= --> */}
    <section id="contact" className="contact">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Contact</h2>
        </div>

        <div className="row no-gutters justify-content-center" data-aos="fade-up">

          <div className="col-lg-10 d-flex justify-content-center align-items-center">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Near Satwara Estate,
                Panchasar Raod,
                Morbi : 363641, India</p>
              </div>

              <div className="email mt-4">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p><a href={"mailto:info@theomhydraulics.com"} >info@theomhydraulics.com</a></p>
              </div>

              <div className="phone mt-4">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p><a href={"tel:+91 6353635583"} > +91 6353635583</a></p>
              </div>

            </div>

          </div>

          <div className=" d-flex align-items-stretch">
            {/* <iframe id="uniq"style="border:0; width: 100%; height: 270px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe> */}
          </div>

        </div>

        <div className="row mt-5 justify-content-center" data-aos="fade-up">
          <div className="col-lg-10">
            <form  method="post" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                </div>
              </div>
              <div className="form-group mt-3">
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" value={text} onChange={Handleonchange} name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="button" onClick={HandleOnclick}>Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>
    {/* <!-- End Contact Section --> */}
        </>
    );
}
export default Contact;