import React from "react";
import { NavLink } from "react-router-dom";
function Services() {
    return(
        <>
    {/* <!-- ======= Services Section ======= --> */}
    <section id="services" className="services section-bg">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Services</h2>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="fade-up">
            <div className="icon-box icon-box-pink">
              <div className="icon"><i className="bx bxl-dribbble"></i></div>
              <h4 className="title"><NavLink >Best Quality Products</NavLink></h4>
              <p className="description">OM HYDRAULICS always provides best quality All Products to our valuable customer.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="fade-up" data-aos-delay="100">
            <div className="icon-box icon-box-cyan">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4 className="title"><NavLink >Long lasting support</NavLink></h4>
              <p className="description">We are having 24x7 Long lasting support that help you always in your problems.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="fade-up" data-aos-delay="200">
            <div className="icon-box icon-box-green">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4 className="title"><NavLink>International market </NavLink></h4>
              <p className="description"> We launched our product in the domestic as well international market</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="fade-up" data-aos-delay="300">
            <div className="icon-box icon-box-blue">
              <div className="icon"><i className="bx bx-world"></i></div>
              <h4 className="title"><NavLink>Licensed & Insured</NavLink></h4>
              <p className="description">We have licensed of Our brand and trademark as well.</p>
            </div>
          </div>

        </div>

      </div>
    </section>
    {/* <!-- End Services Section --> */}
        </>
    );
}
export default Services;