import React from 'react';
import { NavLink } from 'react-router-dom';
import { useState } from "react";
function Footer() {
  const [text, setText] = useState("");
  const HandleOnclick =()=>{
  
   window.open(`https://wa.me/+916353635583?text=${text}`, '_blank');
   setText("");
   }
   const Handleonchange = (event) => {

       setText(event.target.value);
     }
    return(
      <>
    <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              <h3>Om Hydraulics</h3>
              <p>
                Near Satwara Estate, <br/>
                Panchasar Raod, <br/>
                Morbi : 363641, India<br/><br/>
                <strong>Phone:</strong> <a href={"tel:+91 6353635583"} style={{color:"white"}}> +91 6353635583</a><br/>
                <strong>Email:</strong> <a href={"mailto:info@theomhydraulics.com"} style={{color:"white"}}>info@theomhydraulics.com</a><br/>
              </p>
              <div className="social-links mt-3">
                <a href='https://www.facebook.com/people/Om-Hydraulic/100090274472773/?mibextid=ZbWKwL' className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href={"tel:+91 6353635583"} className="instagram"><i className="bi bi-phone"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/">Home</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/About">About us</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Team">Terms of service</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Contact">Contact</NavLink></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Fully-Automatic">Fully Automatic</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Roller-Pan-Mixer">Roller Pan Mixer</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Colour-Mixer">Colour Mixer</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Vibrator-table-set">Vibrator table set</NavLink></li>
              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Vibrator">Vibrator</NavLink></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-newsletter">
          <h4>Our Services</h4>
            <p>For direct whatsapp message</p>
            <form action="" method="post">
            <input type="email"  value={text} onChange={Handleonchange} name="email"/>
              <input type="button" value="Send Message"  onClick={HandleOnclick}/>
            </form>

          </div>

        </div>
      </div>
    </div>

    <div className="container">
        <div className="copyright">
        </div>
        <div className="credits">
        Developed by <NavLink href='#'>Shreyans Padmani & Jay sanghani</NavLink><br/>
            <a href={"tel:+91 7874579457"} style={{color:"white"}}> +91 78745 79457</a>
        </div>
        </div>
  </footer>
      </>
    );
}
export default Footer;