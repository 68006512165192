import React from "react";
import Services from "./Services";
import Slieder from "./Slieder";
function Home() {
    return(
        <>
        <Slieder/>
  {/* <!-- ======= Hero Section ======= --> */}
  {/* <section id="hero"  className="d-flex flex-column justify-content-center align-items-center"> */}
    {/* <div className="container text-center text-md-left" data-aos="fade-up"> */}
      {/* <h1 style={{color : "yellow"}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Welcome to Om Hydrauic</h1> */}
      {/* <h2>We are team of talented designers making websites with Bootstrap</h2> */}
      {/* <a href="#about" className="btn-get-started scrollto">Get Started</a> */}
    {/* </div> */}
   {/* </section> */}
  {/* <!-- End Hero --> */}
      {/* <!-- ======= About Section ======= --> */}
      <section id="about" className="about">
      <div className="container">

        <div className="row">
          <div className="col-xl-6 col-lg-7" data-aos="fade-right">
            <img src="assets/img/about-img.jpg" className="img-fluid" alt=""/>
          </div>
          <div className="col-xl-6 col-lg-5 pt-5 pt-lg-0">
            <h3 data-aos="fade-up">Why Choose OM HYDRAULICS ?</h3>
            <p data-aos="fade-up">
            </p>
            <div className="icon-box" data-aos="fade-up">
              <i className="bx bx-receipt"></i>
              <h4>PROFESSIONAL WORKERS</h4>
              <p>We have professional workers in manufacturing plant.</p>
            </div>

            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <i className="bx bx-cube-alt"></i>
              <h4>EXCELLENCE IN DESIGN</h4>
              <p>By working with some of India’s leading designers, we understand the latest trends. Our design-sense is exemplified by the consistent launching of globally trending kitchenware items.</p>
            </div>

            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <i className="bx bx-cube-alt"></i>
              <h4>ON TIME DELIVERY</h4>
              <p>We provide on time delivory of the sink as we talked.</p>
            </div>

            <div className="icon-box" data-aos="fade-up" data-aos-delay="250">
                  <i className="bx bx-shield"></i>
                  <h4>LATEST TECHNOLOGY</h4>
                  <p>We have latest technology installed in our plants.</p>
                </div>

          </div>
        </div>

      </div>
    </section>
    {/* <!-- End About Section --> */}
    <Services/>
  {/* <!-- ======= Features Section ======= --> */}
    <section id="features" className="features">
      <div className="container">

        <div className="row">
          <div className="col-lg-4 mb-5 mb-lg-0" data-aos="fade-right">
            <ul className="nav nav-tabs flex-column">
              <li className="nav-item">
                <a className="nav-link active show" data-bs-toggle="tab" href="#tab-1">
                  <h4>Fully Automatic</h4>
                </a>
              </li>
              <li className="nav-item mt-2">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                  <h4>Roller Pan Mixer</h4>
                </a>
              </li>
              <li className="nav-item mt-2">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                  <h4>Colour Mixer</h4>
                </a>
              </li>
              <li className="nav-item mt-2">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
                  <h4>Vibrator table set</h4>
                </a>
              </li>
              <li className="nav-item mt-2">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-5">
                  <h4>Vibrator</h4>
                </a>
              </li>
              
            </ul>
          </div>
          <div className="col-lg-6 ml-auto" data-aos="fade-left">
            <div className="tab-content">
              <div className="tab-pane active show" id="tab-1">
                <figure>
                  <img src="assets/img/photo_2023-02-11_11-46-05.jpg" alt="" className="img-fluid"/>
                </figure>
              </div>
              <div className="tab-pane" id="tab-2">
                <figure>
                  <img src="assets/img/blade-pan-mixer-machine-1618817359-5794408.jpeg" alt="" className="img-fluid"/>
                </figure>
              </div>
              <div className="tab-pane" id="tab-3">
                <figure>
                  <img src="assets/img/1617191081.NESTA-12 Drum Type Color Mixer.jpg" alt="" className="img-fluid"/>
                </figure>
              </div>
              <div className="tab-pane" id="tab-4">
                <figure>
                  <img src="assets/img/nesta-paver-block-machinery-2008300805-x4xudebi.jpg" alt="" className="img-fluid"/>
                </figure>
              </div>
              <div className="tab-pane" id="tab-5">
                <figure>
                  <img src="assets/img/images (18).jpeg" alt="" className="img-fluid"/>
                </figure>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    {/* <!-- End Features Section --> */}
        </>
    );
}
export default Home;