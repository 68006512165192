import React from "react";
import { NavLink } from "react-router-dom";
import Services from "./Services";
function About() {
    return(
        <>
    {/* <!-- ======= Breadcrumbs Section ======= --> */}
    <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>About </h2>
          <ol>
            <li><NavLink to="/">Home</NavLink></li>
            <li>About</li>
          </ol>
        </div>

      </div>
    </section>
    {/* <!-- Breadcrumbs Section --> */}
    {/* <!-- ======= About Section ======= --> */}
    <section id="about" className="about">
      <div className="container">

        <div className="row">
          <div className="col-xl-6 col-lg-7" data-aos="fade-right">
            <img src="assets/img/about-img.jpg" className="img-fluid" alt=""/>
          </div>
          <div className="col-xl-6 col-lg-5 pt-5 pt-lg-0">
            <h3 data-aos="fade-up">Why Choose OM HYDRAULICS ?</h3>
            <p data-aos="fade-up">
            </p>
            <div className="icon-box" data-aos="fade-up">
              <i className="bx bx-receipt"></i>
              <h4>PROFESSIONAL WORKERS</h4>
              <p>We have professional workers in manufacturing plant.</p>
            </div>

            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <i className="bx bx-cube-alt"></i>
              <h4>EXCELLENCE IN DESIGN</h4>
              <p>By working with some of India’s leading designers, we understand the latest trends. Our design-sense is exemplified by the consistent launching of globally trending kitchenware items.</p>
            </div>

            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <i className="bx bx-cube-alt"></i>
              <h4>ON TIME DELIVERY</h4>
              <p>We provide on time delivory of the sink as we talked.</p>
            </div>

            <div className="icon-box" data-aos="fade-up" data-aos-delay="250">
                  <i className="bx bx-shield"></i>
                  <h4>LATEST TECHNOLOGY</h4>
                  <p>We have latest technology installed in our plants.</p>
                </div>

          </div>
        </div>

      </div>
    </section>
    {/* <!-- End About Section --> */}
        <Services />
        </>
    );
}
export default About;