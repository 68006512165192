import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Footer from './componate/layout/Footer';
import Heder from './componate/layout/Heder';
import Home from './componate/Basic/Home';
import About from './componate/Basic/About';
import Contact from './componate/Basic/Contact';
import Team from './componate/Basic/Team';
import Product1 from './componate/Content/Product1';
import Product2 from './componate/Content/Product2';
import Product3 from './componate/Content/Product3';
import Product4 from './componate/Content/Product4';
import Product5 from './componate/Content/Product5';

function Layout(){
  return(
      <>
          <Heder/>
          <Outlet/>
          <Footer/>
      </>
  );
}
export default function App() {
  return (
    
       <BrowserRouter>

      <Routes basename="/" > 
            <Route  element={<Layout />}>
              <Route index path='/'  element={<Home/>}/>
              <Route  path='/About'  element={<About/>}/>
              <Route  path='/Team'  element={<Team/>}/>
              <Route  path='/Contact'   element={<Contact/>}/>
              <Route  path='/Fully-Automatic'   element={<Product1/>}/>
              <Route  path='/Roller-Pan-Mixer'   element={<Product2/>}/>
              <Route  path='/Colour-Mixer'   element={<Product3/>}/>
              <Route  path='/Vibrator-table-set'   element={<Product4/>}/>
              <Route  path='/Vibrator'   element={<Product5/>}/>
                  
            </Route>
      </Routes>

     </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
