import React from "react";
import { NavLink } from "react-router-dom";
function Product3() {
    return(
        <>
            {/* <!-- ======= Breadcrumbs Section ======= --> */}
    <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Colour Mixer Details</h2>
          <ol>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink >Machine</NavLink></li>
            <li>Colour Mixer Details</li>
          </ol>
        </div>

      </div>
    </section>
    {/* <!-- Breadcrumbs Section --> */}

    {/* <!-- ======= Portfolio Details Section ======= --> */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">

        <div className="row gy-4">

          <div className="col-lg-5"  data-aos="fade-up">
            <div className="portfolio-details-slider swiper">
              <div className="swiper-wrapper align-items-center">

                <div className="swiper-slide">
                  <img src="assets/img/1617191081.NESTA-12 Drum Type Color Mixer.jpg" alt="Colour Mixer Colour Mixer"/>
                </div>

               
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>

          <div className="col-lg-6"  data-aos="fade-up">
            <div className="portfolio-info">
              <h3>Colour Mixer</h3>

              <ul className="row">
                <li><strong>Maximum Size of the Tiles</strong>   : 600 x 600 x 100 mm</li>
                <li><strong>Structure</strong>           : M. S. Plate with C-Channel</li>
                <li><strong> Capacity</strong>         : 80 Kg.</li>
                <li><strong >Power</strong>                    :  2 H.P. 1440 RPM</li>
                <li><strong>Ideal Speed</strong>                : 40 to 45 RPM</li>
                <li><strong>Machine Size</strong>              : 105 cm x 130 cm x 105 cm</li>
                <li><strong>Drum Size</strong>              : 76 cm x 30cm</li>
              </ul>
            </div>
            <div className="portfolio-description">
              <p>
              </p>
            </div>
          </div>

        </div>

      </div>
    </section>
    {/* <!-- End Portfolio Details Section --> */}
        </>
    );
}
export default Product3;