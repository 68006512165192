import React from "react";
import { NavLink } from "react-router-dom";
function Product5() {
    return(
        <>
            {/* <!-- ======= Breadcrumbs Section ======= --> */}
    <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Vibrator Details</h2>
          <ol>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink>Machine</NavLink></li>
            <li>Vibrator  Details</li>
          </ol>
        </div>

      </div>
    </section>
    {/* <!-- Breadcrumbs Section --> */}

    {/* <!-- ======= Portfolio Details Section ======= --> */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">

        <div className="row gy-4">
        <h3 className="center portfolio-info p"><b>Vibrator</b></h3>
        <div className="col-lg-3"></div>
        <div className="col-lg-6"  data-aos="fade-up">
            <div className="portfolio-details-slider portfolio-info swiper">
              <div className="swiper-wrapper align-items-center">

                <div className="swiper-slide">
                <img src="assets/img/images (18).jpeg" alt="Vibrator"/>
                </div>

               
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>

          <div className="col-lg-3">
            
          </div>

        </div>

      </div>
      
    </section>
    {/* <!-- End Portfolio Details Section --> */}
        </>
    );
}
export default Product5;